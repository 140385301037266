"use server";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Image from "next/image";
import {
  Divider,
  Switch,
  TextField,
  Popover,
  Button,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import NewPng from "/assets/images/new-icon.png";
import SocialCrowdIcon from "/assets/logo/SocialCrowdIcon_new.png";
import { withPageAuthRequired } from "@auth0/nextjs-auth0/client";
import useApi from "lib/useApi";
import { useLocalStorage } from "contexts/LocalStorageContext";
import { FontAwesomeIcon } from "@/helpers/helpers";

const SearchField = ({ value, onChange }) => {
  return (
    <TextField
      fullWidth
      placeholder="Search..."
      value={value}
      autoFocus
      onChange={onChange}
    />
  );
};

export default withPageAuthRequired(function CustomizedSelects({ user }) {
  const { staff, admin, orgId } = useLocalStorage();
  const router = useRouter();
  const [showPaused, setShowPaused] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const localStorage = () => {
    if (typeof window !== "undefined") {
      return window.localStorage;
    }
  };

  const {
    data: orgs,
    mutate,
    error: orgsError,
  } = useApi(`/api/orgs/my_orgs`, true);

  const { data: memberData } = useApi(
    `/api/orgs/${orgId}/members/my_member`,
    true
  );

  const [value, setValue] = useState(orgId?.toString()); // with session

  useEffect(() => {
    setTimeout(() => {
      window.localStorage.setItem("orgSelected", value);
    }, 1000);
  }, [value]);

  const orgIds = new Set(orgs?.map((item) => item?.id));
  const orgsExist = () => {
    if (localStorage()) {
      const alreadyId = localStorage()?.orgSelected;
      return orgIds.has(alreadyId);
    }
  };

  useEffect(() => {
    if (orgsExist()) {
      setValue(localStorage()?.orgSelected);
    } else if (orgs?.length > 0) {
      setValue(orgs[0]?.id);
    }
  }, [orgs]);

  const [data, setData] = useState([
    {
      id: orgId as any,
      name: "Syncing..." as any,
      src: (<CircularProgress />) as any,
      new: false as any,
      profile: true as any,
      status: "" as any,
    },
  ]);

  useEffect(() => {
    if (orgs) {
      setData(
        orgs?.map((item: any) => {
          return { ...item, src: item?.logo_urls[0] || SocialCrowdIcon };
        })
      );
    }
  }, [orgs]);

  const handleChange = (itemId: string) => {
    setValue(itemId);
    window.localStorage.setItem(
      "admin",
      memberData?.role === "admin" ? "true" : "false"
    );
    window.localStorage.removeItem("crowdSelected");
    window.localStorage.setItem("orgSelected", itemId);
    router.push("/");
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (showPaused || item.status !== "paused")
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        className="btn btn-sm bg-white border-2 border-gray-200 text-sm  mr-2 ml-2 capitalize"
        variant="contained"
        onClick={handleClick}
      >
        <div className="flex items-center">
          <Image
            src={data.find((item) => item.id === value)?.src}
            alt={data.find((item) => item.id === value)?.name}
            width={16}
            height={16}
            className="mr-2"
          />
          {data.find((item) => item.id === value)?.name || "Syncing..."}
          {!open ? (
            <FontAwesomeIcon sx={{ ml: 1 }} icon={"chevron-down"} />
          ) : (
            <FontAwesomeIcon sx={{ ml: 1 }} icon={"chevron-up"} />
          )}
        </div>
      </Button>
      <Popover
        id={id}
        sx={{ mt: 6, maxHeight: 400 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div className="rounded">
          <div className="p-2">
            <SearchField value={searchTerm} onChange={handleSearchChange} />
            <div className="flex items-center justify-end pr-4 py-3">
              <Switch
                size="small"
                checked={showPaused}
                onChange={() => setShowPaused(!showPaused)}
              />{" "}
              <div className="text-xs font-bold">Show Paused</div>
            </div>
          </div>

          <Divider />
          {filteredData?.map((item) => (
            <div
              key={item.id}
              onClick={() => {
                handleChange(item.id);
                handleClose();
              }}
              className={`flex items-center cursor-pointer hover:bg-gray-100 pr-4 pl-4 py-2 justify-between ${item.id === value ? "bg-gray-200" : item.status === "paused" ? "bg-orange-100" : ""}`}
            >
              <div className="flex items-center">
                <Image
                  src={item.src}
                  alt={item.name}
                  width={20}
                  height={20}
                  className="mr-2"
                />
                <div className="text-xs font-bold">{item.name}</div>
                {item.status === "paused" && (
                  <div className="text-[9px] font-bold ml-2 text-red-500">
                    Paused
                  </div>
                )}
              </div>
              {item.id === value && (
                <FontAwesomeIcon sx={{ ml: 1 }} icon={"check"} />
              )}
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  width: 300,
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "&& > svg": {
    // color: "#000",
    width: "1.7em",
  },
  "& .MuiInputBase-input": {
    borderRadius: 10,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #866baa42",
    fontSize: 16,
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 10,
      borderColor: "#ac7ebc",
      boxShadow: "0 0 0 0.2rem #cbbcd73e",
    },
  },
}));

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
