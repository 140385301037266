const getFilenameFromHeader = (contentDisposition: string | null, url: string): string => {
  // Get base filename from content-disposition
  let filename = 'earnings-report.csv';
  if (contentDisposition) {
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(contentDisposition);
    if (matches && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  // Extract dates from URL
  try {
    const urlParams = new URL(url, 'http://dummy.com').searchParams;
    const startDate = new Date(urlParams.get('start_date') || '');
    const endDate = new Date(urlParams.get('end_date') || '');
    
    if (startDate && endDate) {
      // Remove .csv extension, add dates, then re-add extension
      const baseFilename = filename.replace('.csv', '');
      return `${baseFilename}-${startDate.toISOString().split('T')[0]}-to-${endDate.toISOString().split('T')[0]}.csv`;
    }
  } catch (e) {
    console.warn('Could not parse dates from URL');
  }

  return filename;
};

const fileDownloader = async (url: string) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token is not available");
    }

    const baseUrl = process.env.NEXT_PUBLIC_CUSTOMER_API_HOST;
    const encodedUrl = new URL(url, baseUrl).toString();
    
    const response = await fetch(encodedUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'text/csv',
      },
    });

    if (!response.ok) {
      throw new Error(`Download failed: ${response.statusText}`);
    }

    const contentDisposition = response.headers.get('content-disposition');
    const filename = getFilenameFromHeader(contentDisposition, url);

    const text = await response.text();
    const BOM = '\uFEFF';
    const csvContent = BOM + text;
    
    const blob = new Blob([csvContent], { 
      type: 'application/csv;charset=utf-8;' 
    });
    
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = 'none';
    a.href = downloadUrl;
    a.download = filename;
    
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(() => {
      window.URL.revokeObjectURL(downloadUrl);
    }, 100);
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error;
  }
};

export default fileDownloader;
