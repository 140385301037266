"use client";

import {
  Stack,
  TablePaginationProps,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import {
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  GridPagination,
} from "@mui/x-data-grid-premium";

export function FontAwesomeIcon({
  icon,
  color,
  size,
  containerSize,
  sx,
  style,
  className,
  faSpin,
}: {
  icon: string;
  color?: string;
  size?: string;
  containerSize?: string;
  sx?: any;
  style?: string;
  className?: string;
  faSpin?: boolean;
}) {
  return (
    <Stack
      color={color}
      width={containerSize}
      alignItems="center"
      justifyContent="center"
      sx={sx}
      className={className}
    >
      <i
        className={`fa${style ? style : "r"} fa-${icon} ${
          faSpin ? "fa-spin" : ""
        } fa-${size}x`}
      />
    </Stack>
  );
}

export function titleName(title: string) {
  return (
    <Stack
      mt={1}
      mb={1}
      style={{
        fontWeight: "bold",
        fontSize: "0.7rem",
        color: "#000000",
        textTransform: "uppercase",
        whiteSpace: "nowrap",
      }}
    >
      {title}
    </Stack>
  );
}

export function detailsTitle(title: string) {
  return (
    <Stack
      mt={1}
      mb={1}
      style={{
        // fontWeight: "bold",
        fontSize: "0.7rem",
        color: "#374151",
        textTransform: "uppercase",
        whiteSpace: "nowrap",
      }}
    >
      {title}
    </Stack>
  );
}

export function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="secondary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
      // Add boundaryCount and siblingCount to customize the pagination appearance
      boundaryCount={0} // Shows 1 page number at the start and end
      siblingCount={1} // Shows 2 pages around the current page
    />
  );
}

export function CustomPagination(props: any) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export function title(title: string) {
  return (
    <Stack
      sx={{
        color: "#374151",
        fontSize: "14px",
        fontWeight: "600",
        textTransform: "uppercase",
        border: "none !important",
        whiteSpace: "nowrap",
      }}
    >
      {title}
    </Stack>
  );
}

export function generateRandomId() {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let id = "";
  for (let i = 0; i < 4; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters[randomIndex];
  }
  return id;
}

export function randomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function DenoteRemover(value) {
  let newvalue = "";
  newvalue = value
    ?.replace(/_/g, " ")
    ?.replace(/\b\w/g, (c) => c.toUpperCase());
  return newvalue;
}

export function maskPhone(str) {
  // First, remove any non-digit characters from the string
  const cleanStr = str?.replace(/\D/g, "");

  // Next, check if the clean string is empty or not
  if (cleanStr?.length === 0) {
    // If the string is empty, return an empty string
    return "";
  } else if (cleanStr?.length <= 4) {
    // If the string has four or fewer digits, return the entire string
    return cleanStr;
  } else if (cleanStr?.length <= 7) {
    // If the string has between five and seven digits, mask the first digit and return the rest
    return cleanStr?.replace(/(\d{1})(\d{0,3})/, "($1XX) XXX-XXXX");
  } else {
    // If the string has eight or more digits, mask the first three and return the rest
    return cleanStr?.replace(/(\d{3})(\d{0,3})(\d{0,4})/, "($1) $2-$3");
  }
}

export function getLighterColor(color: string, percent: number) {
  const num = parseInt(color.replace("#", ""), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + Math.floor((amt * (255 - (num >> 16))) / 255);
  const G =
    ((num >> 8) & 0x00ff) +
    Math.floor((amt * (255 - ((num >> 8) & 0x00ff))) / 255);
  const B =
    (num & 0x0000ff) + Math.floor((amt * (255 - (num & 0x0000ff))) / 255);
  return (
    "#" +
    (
      0x1000000 +
      Math.min(Math.floor(R), 255) * 0x10000 +
      Math.min(Math.floor(G), 255) * 0x100 +
      Math.min(Math.floor(B), 255)
    )
      .toString(16)
      .slice(1)
  );
}

export async function copyToClipboard(textToCopy) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";

    document.body.prepend(textArea);
    textArea.select();

    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textArea.value);
      } else {
        document.execCommand("copy");
      }
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
}

export function createRandomColors(num: number) {
  const colors: string[] = [];
  const primaryColors = [
    "#FF69B4",
    "#6F23FF",
    "#46B358",
    "#FFCB30",
    "#3DC6FF",
    "#FF244E",
  ]; // pink and purple
  for (let i = 0; i < num; i++) {
    if (i < primaryColors.length) {
      colors.push(primaryColors[i]);
    } else {
      const r = Math.floor(Math.random() * 156); // range: 100-255
      const g = Math.floor(Math.random() * 156); // range: 100-255
      const b = Math.floor(Math.random() * 156); // range: 100-255
      colors.push(`rgb(${r}, ${g}, ${b})`);
    }
  }
  return colors;
}

export function titles(title) {
  return (
    <Stack
      sx={{
        color: "#6F23FF",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: "0.8rem",
        mt: 2,
      }}
      direction="row"
      spacing={2}
    >
      {title}
    </Stack>
  );
}

export function abbreviateNumber(value) {
  if (value === null || value === undefined) {
    return "∞"; // Placeholder for undefined or null values
  }

  let num = Number(value);
  if (!isFinite(num)) {
    return "∞"; // Placeholder for non-finite numbers
  }

  const suffixes = ["", "k", "M", "B", "T"];
  let shortValue;
  const integerPart = Math.floor(num); // Use integer part for calculating suffix
  const strValue = integerPart.toString();
  const suffixNum = Math.max(0, Math.floor((strValue.length - 1) / 3));

  if (num < 1000) {
    // Directly format numbers below 1000, without unnecessary decimal places for whole numbers
    shortValue = num % 1 === 0 ? num.toString() : num.toFixed(1);
  } else {
    // Scale number for abbreviation
    const scale = Math.pow(1000, suffixNum);
    shortValue = Math.floor((num / scale) * 10) / 10; // Keep one decimal place, rounding down

    // Ensure no unnecessary decimal place for whole numbers, while allowing one decimal place otherwise
    shortValue =
      shortValue % 1 === 0 ? shortValue.toString() : shortValue.toFixed(1);
  }

  return shortValue + suffixes[suffixNum];
}

export function abbreviateRange(value: string, ascending?: boolean): string {
  const endingNumber = !ascending ? "∞" : "0";
  const values = value?.split(" - ");
  const abbreviatedValues = values?.map((v) =>
    v === "∞" ? endingNumber : abbreviateNumber(v)
  );
  return abbreviatedValues?.join(" - ");
}

export const describeOperator = (
  operator: string,
  isDescending: boolean = false
): string => {
  const [start, end] = operator?.split(" - ");

  // Clean up any comparison operators and number formatting
  const cleanNumber = (value: string) => {
    return value
      ?.replace(/[<>=]/g, '')  
      ?.replace(/\.00/g, '')   
      ?.trim();
  };

  // For descending ranges
  if (isDescending) {
    // First range (highest range)
    if (cleanNumber(start) === "Inf") {
      return `Greater than or equal to ${cleanNumber(end)}`;
    }
    // Last range (lowest range)
    if (end === "0") {
      return `Less than ${cleanNumber(start)}`;
    }
    // Middle ranges
    return `Less than ${cleanNumber(start)} or greater than or equal to ${cleanNumber(end)}`;
  } 
  // For ascending ranges
  else {
    // Last range (infinity case)
    if (cleanNumber(end) === "0") {
      return `Less than ${cleanNumber(start)}`;
    }
    // Middle ranges
    return `Greater than ${cleanNumber(start)} or less than or equal to ${cleanNumber(end)}`;
  }
};

export const BlackTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    border: "2px solid #6F23FF",
    textAlign: "center",
  },
}));

export function convertToHyperlinks(text: string) {
  if (!text) return "";

  if (text.includes("</")) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(`<div>${text}</div>`, "text/html");

    // Process all links
    const links = doc.getElementsByTagName("a");
    for (let link of links) {
      const href = link.getAttribute("href");
      const absoluteUrl =
        href && !href.startsWith("http") ? `https://${href}` : href!;
      link.setAttribute("href", absoluteUrl);
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener noreferrer nofollow");
    }

    return (
      <div
        onClick={(e) => {
          const target = e.target as HTMLElement;
          if (target.tagName === "A") {
            e.preventDefault();
            e.stopPropagation();
            const href = target.getAttribute("href");
            if (href) window.open(href, "_blank");
          }
        }}
        // style={{
        //   "& ul": { listStyleType: "disc", paddingLeft: "20px" },
        //   "& ol": { listStyleType: "decimal", paddingLeft: "20px" },
        // }}
        dangerouslySetInnerHTML={{
          __html: doc
            .querySelector("div")!
            .innerHTML.replace(
              /<ul>/g,
              '<ul style="list-style-type: disc; padding-left: 20px;">'
            )
            .replace(
              /<ol>/g,
              '<ol style="list-style-type: decimal; padding-left: 20px;">'
            ),
        }}
      />
    );
  }

  // Rest of the function for plain text...
  const escapeHtml = (str: string) => {
    return str
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  };

  const escapedText = escapeHtml(text);
  const urlPattern =
    /(https?:\/\/[^\s]+)|(?:^|\s)((?:[\w-]+\.)+[a-z]{2,}(?:\/[^\s]*)?)/gi;

  const htmlContent = escapedText.replace(
    urlPattern,
    (match, p1, p2, offset, string) => {
      if (string.charAt(offset - 1) === "@") return match;

      const cleanMatch = p2 || match;
      const urlWithProtocol = cleanMatch.startsWith("http")
        ? cleanMatch
        : `https://${cleanMatch}`;
      const leadingSpace = match !== cleanMatch ? " " : "";

      return `${leadingSpace}<a href="${urlWithProtocol}" target="_blank" rel="noopener noreferrer nofollow">${cleanMatch}</a>`;
    }
  );

  return (
    <div
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.tagName === "A") {
          e.preventDefault();
          e.stopPropagation();
          const href = target.getAttribute("href");
          if (href) window.open(href, "_blank");
        }
      }}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
}

export function sanitizeHtmlToPlainText(messageText: string): string {
  // Function to safely decode URLs
  const decodeUrl = (url: string) => {
    try {
      // First try to decode once
      let decoded = decodeURIComponent(url);

      // Check if it's still encoded (contains %)
      while (decoded.includes("%")) {
        const prevDecoded = decoded;
        decoded = decodeURIComponent(decoded);
        // Break if no further decoding is happening
        if (prevDecoded === decoded) break;
      }
      return decoded;
    } catch (e) {
      // If decoding fails, return original
      return url;
    }
  };

  const result = messageText
    // First decode HTML entities
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'")
    // Handle URLs - split by spaces and decode each part if it looks like a URL
    .split(/\s+/)
    .map((part) => {
      if (
        part.includes("http") ||
        part.includes("www.") ||
        part.includes("%")
      ) {
        return decodeUrl(part);
      }
      return part;
    })
    .join(" ")
    // Then proceed with HTML tag removal
    .replace(/<p>/gi, "")
    .replace(/<\/p>/gi, "\n")
    .replace(/<strong>/gi, "")
    .replace(/<\/strong>/gi, "")
    .replace(/<em>/gi, "")
    .replace(/<\/em>/gi, "")
    .replace(/<s>/gi, "")
    .replace(/<\/s>/gi, "")
    .replace(/<br[^>]*>/gi, "\n")
    .replace(/<a[^>]*href=["']([^"']+)["'][^>]*>.*?<\/a>/gi, "$1")
    .replace(/<[^>]*>/g, "")
    .replace(/\n{3,}/g, "\n\n")
    .trim();
  return result;
}

export const convertHtmlToPlainText = (html: string) => {
  const temp = document.createElement("div");
  temp.innerHTML = html;

  const result = temp.innerHTML
    .replace(/<\/p>/g, "</p>\n")
    .replace(/<br\s*\/?>/g, "\n")
    .replace(/\n\n+/g, "\n\n")
    .trim();
  return result;
};
